import { RadiusLabel, RadiusSlider, SliderContainer } from './Slider.styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { StudioActions } from '../../store/studio';
import { AutocompleteOptionType } from '../../models/AutocompleteOption';
import useAllVisibleStudios from '../../hooks/useAllVisibleStudios';

const Slider = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);

  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const [sliderValue, setSliderValue] = useState(50);
  const filter = useSelector(StudioSelectors.getFilter);
  const { data: allVisibleStudios = [] } = useAllVisibleStudios();

  const handleSliderChange = (_: Event, value: number | number[]) => {
    setSliderValue(value as number);
  };

  useEffect(() => {
    setSliderValue(radius);
  }, [radius]);

  const handleSliderChangeCommitted = () => {
    if (inputInformation.length > 0) {
      dispatch(StudioActions.setRadiusFilterValue(sliderValue));

      dispatch(
        StudioActions.filterVisibleStudioGroupItems({
          inputInformation,
          type: AutocompleteOptionType.Prediction,
          filter,
          radius: sliderValue,
          allVisibleStudios,
        }),
      );
    }
  };

  return (
    <SliderContainer>
      <RadiusLabel variant='caption'>
        {intl.formatMessage({
          id: 'slider.radius',
          defaultMessage: 'Radius:',
        })}
        {inputInformation.length === 0 ? '' : ' (' + radius + ' km)'}
      </RadiusLabel>
      <RadiusSlider
        disabled={inputInformation.length === 0}
        value={sliderValue}
        min={1}
        step={1}
        max={100}
        size='small'
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        valueLabelDisplay='auto'
        aria-labelledby='radius-slider'
      />
    </SliderContainer>
  );
};

export default Slider;
